counter {
  text-align: right;
  &.big {
    @include default-margin(margin-top);
    @include default-margin(margin-bottom);
    border-top: 1px solid $red;
    color: $red;
    padding: 1rem 0;
  }
  p {
    margin: 0;
    font-weight: bold;
    white-space: break-spaces;
  }
  div {
    color: $red;
    font-size: 4rem;
    font-weight: bold;
  }
  &.final div::after {
    content: ".";
  }
}

.text-content,
.partner-and-counseling {
  p {
    white-space: pre-wrap;
    &:last-child {
      margin-bottom: $block-margin;
    }
  }
  a {
    white-space: pre-wrap;
    text-decoration: underline;
    max-width: 100%;
  }
  .image-wrap {
    margin: $block-margin 0;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}

.partner-and-counseling {
  margin: 0 0 $block-margin 0;
  border-bottom: 1px solid $light-gray;
}

.textarea-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}

;@import "sass-embedded-legacy-load-done:14";