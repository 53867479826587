// mixins
@mixin breakpoint($size) {
  @media screen and (min-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

@mixin default-margin($style) {
  @each $name, $size in $breakpoints {
    @include breakpoint($name) {
      #{$style}: map-get($grid-gutter, $name);
    }
  }
}

@function rem-calc($pixel) {
  @return calc($pixel / 16 * 1rem);
}

// utility classes
.hide {
  display: none !important;
}

;@import "sass-embedded-legacy-load-done:2";