@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fade-in {
  from {
    visibility: visible;
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes from-left {
  from {
    visibility: visible;
    opacity: 0;
    transform: translateX(-100vw);
  }
  to {
    visibility: visible;
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes from-right {
  from {
    visibility: visible;
    opacity: 0;
    transform: translateX(100vw);
  }
  to {
    visibility: visible;
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes pop-up {
  from {
    max-height: 0px;
  }
  to {
    max-height: 100vh;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(calc(-100vh - 1rem));
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(0);
    visibility: visible;
  }
  to {
    transform: translateY(calc(-100vh - 1rem));
    visibility: hidden;
  }
}

;@import "sass-embedded-legacy-load-done:6";