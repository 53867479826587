step {
  display: flex;
  flex-direction: column;

  & > *:last-child:not(.hide) {
    margin-bottom: 10vh; // browser-controls on mobile
  }

  & > buttons:last-child:not(.hide) {
    margin-top: $block-margin;
  }

  & > *:first-child:not(.hide) {
    margin-top: 2 * $block-margin;
    &.text-content:first-of-type {
      margin-top: 0;
      > *:first-child {
        margin-top: 2 * $block-margin;
      }
    }
  }

  visibility: hidden;
  &.from-right {
    animation: from-right 0.4s both ease;
  }
  &.from-left {
    animation: from-left 0.4s both ease;
  }
}

#step-0 button[type="next"],
#step-6 button[type="submit"] {
  width: 100%;
  margin: 1.3rem 0;
  padding: 1.66rem;
}

#step-7.from-right {
  animation: none;
  visibility: visible;
}

#step-0 {
  animation: none;
  visibility: visible;
}

#step-1 {
  & > *:first-child:not(.hide) {
    margin-top: 0;
  }

  & > *:last-child:not(.hide) {
    margin-bottom: 0;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;

    column-gap: 0;
    row-gap: 0;

    checkbox {
      background: $red;

      display: flex;
      justify-content: center;
      padding: rem-calc(40) 0;

      width: calc(100% + 2rem);
      margin-left: -1rem;
      margin-right: -1rem;

      @include breakpoint(md) {
        margin: 0;
        width: 100%;
      }

      label {
        position: relative;
        color: $white;
        background: $blue;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 15rem;
        width: 15rem;
        border-radius: 50%;

        padding: 1rem;

        font-size: 1.3rem;
        font-weight: bold;

        span {
          color: $blue;

          box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);

          font-size: 1rem;
          font-weight: normal;

          padding: 0.5rem 1rem 0.3rem 1rem;
          border-radius: 5px;

          background: $white;
          position: absolute;
          left: 1rem;
          bottom: 1rem;
        }
      }
    }

    checkbox:first-of-type {
      background: $blue;
      label {
        color: $white;
        background: $red;
        border-bottom-right-radius: 0;
        span {
          color: $red;
          left: unset;
          right: 1rem;
        }
      }
    }
    checkbox:not(:first-of-type) {
      label {
        border-bottom-left-radius: 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:11";